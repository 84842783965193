'use strict';

PaginationControl.$inject = ['mmPaginationConstants'];
WizardPage.$inject = ['$state'];
WizardController.$inject = ['$rootScope'];
StateHelper.$inject = ['$state'];
angular.module('resources.ui', ['resources.ui.templates', 'resources.ui.breadcrumb', 'resources.ui.navbarHeader', 'resources.ui.appbar', 'resources.ui.loading', 'resources.ui.table', 'resources.ui.wizard', 'resources.ui.validation']);
angular.module('resources.ui.templates', []);
//empty placeholder for templates loaded later
function Appbar() {
    return {
        restrict: 'E',
        templateUrl: '/shared/ui-components/appbar/mmAppbar.html',
        scope: {
            color: '@'
        }
    };
}
angular.module('resources.ui.appbar', []).directive('mmAppbar', Appbar);

angular.module('resources.ui.loading', []).directive('mmLoading', ['$http', '$timeout', function ($http, $timeout) {
    return {
        restrict: 'E',
        templateUrl: '/shared/ui-components/loading/mmLoading.html',
        scope: {
            message: '@'
        },
        link: function link(scope, element) {
            element.hide();

            scope.isLoading = function () {
                return $http.pendingRequests.length > 0;
            };

            scope.$watch(scope.isLoading, function (v) {
                $timeout(function () {
                    // check again if still loading after 200 ms before showing splash
                    if (v && scope.isLoading()) {
                        element.show();
                    } else {
                        element.hide();
                    }
                }, 200);
            });
        }
    };
}]);

function Breadcrumbs() {

    return {
        restrict: 'E',
        templateUrl: '/shared/ui-components/breadcrumbs/mmBreadcrumbs.html',
        scope: {
            disableFirstState: '=?'
        },
        controller: ['$scope', '$state', 'StateHelper', '$rootScope', function controller($scope, $state, StateHelper, $rootScope) {
            $scope.states = [];

            /**
             * Refresh the states on a successful state change
             */
            $rootScope.$on('$stateChangeSuccess', function () {
                updateStates();
            });

            /**
             * Loops through all state names to create a scoped variable which contains data the breadcrumb markup needs.
             */
            function updateStates() {
                $scope.states.length = 0;
                var stateNames = StateHelper.getStateNames();

                angular.forEach(stateNames, function (stateName) {
                    var state = {
                        name: stateName,
                        pageTitle: $state.get(stateName).data.pageTitle,
                        active: false
                    };

                    $scope.states.push(state);
                });

                _.last($scope.states).active = true;
            }

            /**
             * Init the states on first load.
             */
            updateStates();
        }]
    };
}

function StateHelper($state) {

    function getStates(stateName, states) {
        if (!states) {
            states = [];
        }

        states.push(stateName);
        var rest = stateName.substring(0, stateName.lastIndexOf('.'));
        if (rest.length > 0) {
            return getStates(rest, states);
        } else {
            return states;
        }
    }

    return {
        /**
         * Helper function to return all state names from the root and up to the current state based on $state
         * @returns {*}
         */
        getStateNames: function getStateNames() {
            return getStates($state.current.name).reverse();
        }
    };
}

angular.module('resources.ui.breadcrumb', []).directive('mmBreadcrumbs', Breadcrumbs).factory('StateHelper', StateHelper);

function NavbarHeader() {
    return {
        restrict: 'E',
        templateUrl: '/shared/ui-components/navbarHeader/mmNavbarHeader.html',
        scope: {
            title: '@',
            titleLink: '@',
            toggleId: '@'
        }
    };
}
angular.module('resources.ui.navbarHeader', []).directive('mmNavbarHeader', NavbarHeader);

angular.module('resources.ui.table', ['resources.ui.table.pagination', 'resources.ui.table.sorter']);
angular.module('resources.ui.validation', []).directive('mmEquals', ['$parse', function ($parse) {
    return {
        require: 'ngModel',
        link: function link(scope, element, attr, ctrl) {
            var getCompareWith;
            /*
            * Creates a 'getter' that takes scope as argument
            */
            attr.$observe('mmEquals', function (d) {
                getCompareWith = $parse(d);
            });

            ctrl.$validators.mmEquals = function (modelValue) {
                var compareWith = getCompareWith(scope);
                return compareWith == modelValue;
            };

            scope.$watch(attr.mmEquals, function () {
                ctrl.$validate();
            });
        }
    };
}]);
angular.module('resources.ui.templates', []).run(['$templateCache', function ($templateCache) {
    $templateCache.put('/shared/ui-components/appbar/mmAppbar.html', '<div ng-init="appbarColor = {\'background-color\': color }">\n    <div class="mm-appbar" ng-style="appbarColor"></div>\n</div>');
    $templateCache.put('/shared/ui-components/loading/mmLoading.html', '<div id="loadingOverlay" ng-cloak>\n    <div id="loading" class="text-muted fa-2x text-center">\n        <span>\n            <i class="fa fa-spinner fa-spin"></i> <span ng-if="message">{{ message }}</span>\n        </span>\n    </div>\n</div>\n');
    $templateCache.put('/shared/ui-components/breadcrumbs/mmBreadcrumbs.html', '<ol class="breadcrumb">\n    <li ng-repeat="state in states" ng-class="{\'active\': state.active}">\n        <a ng-if="!state.active && (!$first || !disableFirstState)" href="" ui-sref="{{ state.name }}" ui-sref-opts="{reload:true}">{{ state.pageTitle }}</a>\n        <span ng-if="state.active || ($first && disableFirstState)">{{ state.pageTitle }}</span>\n    </li>\n</ol>\n');
    $templateCache.put('/shared/ui-components/navbarHeader/mmNavbarHeader.html', '<div class="navbar-header">\n    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="{{ toggleId }}"\n            aria-expanded="false">\n        <i class="fa fa-bars fa-lg fa-fw"></i>\n    </button>\n    <a ui-sref="{{ titleLink }}">\n        <div class="header-logo"></div>\n    </a>\n    <a class="navbar-brand" ui-sref="{{ titleLink }}" accesskey=".">{{ title }}</a>\n</div>');
    $templateCache.put('/shared/ui-components/wizard/mmWizard.html', '<div class="wizard-container">\n    <div class="wizard-nav-container">\n        <ul class="nav wizard-nav with-arrows">\n            <li ng-repeat="page in wizardController.pages">\n                <button type="button" ng-click="wizardController.goTo(page)"\n                        ng-class="{\'active\': page.isActive()}">{{ page.header }}\n                </button>\n            </li>\n        </ul>\n    </div>\n    <div class="wizard-content" ng-transclude>\n\n    </div>\n\n    <div class="wizard-actions-container">\n        <div ng-if="!wizardController.hideWizardActions" class="row">\n            <div class="col-md-12">\n                <div class="wizard-controls">\n                    <div>\n                        <button type="button" class="btn btn-wizard-next with-icon" ng-disabled="!wizardController.hasNextPage()" ng-click="wizardController.goToNextPage()">\n                            N\xE4sta steg\n                        </button>\n                        <button type="button" class="btn btn-save with-icon" ng-click="wizardController.saveAndExit()">\n                            Spara och avsluta\n                        </button>\n                        <button type="button" class="btn btn-wizard-prev with-icon" ng-disabled="!wizardController.hasPreviousPage()" ng-click="wizardController.goToPreviousPage()">\n                            F\xF6reg\xE5ende\n                        </button>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n');
    $templateCache.put('/shared/ui-components/wizard/mmWizardPage.html', '<div ng-if="pageController.isActive()" ng-transclude></div>\n');
    $templateCache.put('/shared/ui-components/table/tableSorter/mmTableSorter.html', '<th class="sortableColumn"\n    ng-class="{\'sort-active\':order===by, \'sort-asc\':order===by && !reverse, \'sort-desc\':order===by && reverse}"\n    ng-click="onClick()">\n    {{title}}\n    <span ng-transclude></span>\n</th>\n');
    $templateCache.put('/shared/ui-components/table/pagination/mmPaginationControl.html', '<div ng-if="paginationController.paginationModel.max > 0" class="pagination-actions">\n    <nav class="pagination-nav">\n        <ul class="pagination">\n            <li>\n                <a href="" aria-label="Previous" ng-click="paginationController.showPrevious()">\n                    <span aria-hidden="true">&laquo;</span>\n                </a>\n            </li>\n            <li ng-repeat="pageIndex in paginationController.pages"\n                ng-class="{\'active\':paginationController.isActive(pageIndex)}"><a href=""\n                                                                                  ng-click="paginationController.goTo(pageIndex)">{{\n                pageIndex }}</a></li>\n            <li>\n                <a href="" aria-label="Next" ng-click="paginationController.showNext()">\n                    <span aria-hidden="true">&raquo;</span>\n                </a>\n            </li>\n        </ul>\n    </nav>\n\n    <div class="btn-group page-sizer" role="group">\n        <button ng-repeat="alternative in paginationController.maxPerPageAlternatives" type="button" class="btn btn-default"\n                ng-class="{active:paginationController.paginationModel.maxPerPage === alternative || (paginationController.paginationModel.maxPerPage === paginationController.paginationModel.max && alternative === \'Alla\')}"\n                ng-click="paginationController.setMaxPerPage(alternative)">{{ alternative }}</button>\n    </div>\n\n    <p>Visar {{ paginationController.paginationModel.beginIndex +1 }}-{{ paginationController.endIndex() }} av totalt {{\n        paginationController.paginationModel.max }}</p>\n</div>\n');
}]);
function Wizard() {

    return {
        restrict: 'E',
        transclude: true,
        scope: {
            saveCallback: '&',
            navigateCallback: '&',
            hideWizardActions: '='
        },
        bindToController: true,
        controller: 'WizardController',
        controllerAs: 'wizardController',
        templateUrl: '/shared/ui-components/wizard/mmWizard.html'
    };
}

function WizardController($rootScope) {
    var pages = this.pages = [];
    var self = this;

    function getActivePage() {
        return _.find(pages, function (page) {
            return page.isActive();
        });
    }

    function setActivePageSubmitted() {
        var activePage = getActivePage();
        if (activePage.form) {
            activePage.form.$submitted = true;
        }
    }

    function indexOfActivePage() {
        return pages.indexOf(getActivePage());
    }

    function getNextPage() {
        return pages[indexOfActivePage() + 1];
    }

    function getPreviousPage() {
        return pages[indexOfActivePage() - 1];
    }

    /**
     * Activates the given page and set all others as inactive,
     * but only if the optional navigateCallback function is not returning false
     * @param page
     */
    function togglePage(page) {
        var fromPage = getActivePage();
        // do nothing if already on the active page
        if (fromPage === page) {
            return;
        }

        // sets the page to submitted if it has a form and navigating forward
        if (page.order > fromPage.order) {
            setActivePageSubmitted();
        }

        // call the optional callback function which can stop the navigation if returning false
        if (self.navigateCallback({ from: fromPage, to: page }) === false) {
            return;
        }

        // set the new page to active
        page.setActive();

        // set all other pages to inactive
        angular.forEach(pages, function (otherPage) {
            if (otherPage !== page) {
                otherPage.setInactive();
            }
        });
    }

    $rootScope.$on('$stateChangeSuccess', function (event, toState) {
        var matchingPage = _.find(pages, function (page) {
            return toState.name === page.state;
        });

        if (matchingPage) {
            togglePage(matchingPage);
        }
    });

    /**
     * Registers a page (from mmWizardPage)
     * @param page
     */
    this.addPage = function (page) {
        pages.push(page);
    };

    /**
     * Navigates to the given page
     * @param page
     */
    this.goTo = function (page) {
        togglePage(page);
    };

    /**
     * Navigates to the next page
     */
    this.goToNextPage = function () {
        if (this.hasNextPage()) {
            togglePage(getNextPage());
        }
    };

    /**
     * Navigates to the previous page
     */
    this.goToPreviousPage = function () {
        if (this.hasPreviousPage()) {
            togglePage(getPreviousPage());
        }
    };

    /**
     * If there are any previous pages
     * @returns {boolean}
     */
    this.hasPreviousPage = function () {
        return indexOfActivePage() > 0;
    };

    /**
     * If there are any next pages
     * @returns {boolean}
     */
    this.hasNextPage = function () {
        var index = indexOfActivePage();
        return index < pages.length - 1 && index !== -1;
    };

    /**
     * To save and exit
     */
    this.saveAndExit = function () {
        setActivePageSubmitted();
        this.saveCallback({ activePage: getActivePage() });
    };
}
angular.module('resources.ui.wizard', ['ui.router']).controller('WizardController', WizardController).directive('mmWizard', Wizard);

function WizardPage($state) {

    return {
        restrict: 'E',
        require: '^mmWizard',
        scope: {
            header: '@',
            active: '@',
            state: '@',
            form: '=?'
        },
        transclude: true,
        replace: true,
        templateUrl: '/shared/ui-components/wizard/mmWizardPage.html',
        controllerAs: 'pageController',
        bindToController: true,
        controller: function controller() {
            this.activePage = false;

            this.setActive = function () {
                if (this.state) {
                    $state.go(this.state);
                }

                this.activePage = true;
            };

            this.setInactive = function () {
                this.activePage = false;
            };

            this.isActive = function () {
                return this.activePage;
            };
        },
        link: function link(scope, element, attr, mmWizardController) {
            mmWizardController.addPage(scope.pageController);
            scope.pageController.order = mmWizardController.pages.indexOf(scope.pageController);

            //if not using states the active page is determined by the optional 'active' parameter
            if (!scope.pageController.state && scope.pageController.active) {
                scope.pageController.activePage = true;
            }
            //and if using states it can handle the flag it self for the initial load
            else if ($state.current.name === scope.pageController.state) {
                    scope.pageController.activePage = true;
                }
        }
    };
}
angular.module('resources.ui.wizard').directive('mmWizardPage', WizardPage);

angular.module('resources.ui.table.sorter', []).directive('mmTableSorter', function () {
    var linker = function linker(scope, element, attrs) {
        scope.onClick = function () {
            if (scope.order === scope.by) {
                scope.reverse = !scope.reverse;
            } else {
                scope.by = scope.order;
                scope.reverse = false;
            }
        };
    };

    return {
        restrict: 'A',
        replace: true,
        templateUrl: '/shared/ui-components/table/tableSorter/mmTableSorter.html',
        transclude: true,
        scope: {
            title: '@',
            order: '@',
            by: '=',
            reverse: '='
        },
        link: linker
    };
});

function PaginationControl(mmPaginationConstants) {
    return {
        restrict: 'E',
        scope: {
            paginationModel: '='
        },
        templateUrl: '/shared/ui-components/table/pagination/mmPaginationControl.html',
        controllerAs: 'paginationController',
        bindToController: true,
        controller: function controller() {
            var vm = this;

            /**
             * Returns the index on the first element for the given page, i.e
             * Page 1 = beginIndex 0
             * Page 2 = beginIndex 15 (if maxPerPage is set to 15)
             * Page 3 = beginIndex 30 ...
             *
             * @param page
             * @returns {number}
             */
            function getBeginIndexForPage(page) {
                return vm.paginationModel.maxPerPage * (page - 1);
            }

            /**
             * Re-constructing the array with page numbers when moving left.
             * Example:
             *
             * Say you are displaying the page numbers: [6, 7, 8, 9, 10]
             * When you click left, you want to display the page numbers: [1, 2, 3, 4, 5]
             *
             * @param firstPage the first page number in the range, i.e 6.
             */
            function shiftPagesLeft(firstPage) {
                vm.pages = _.range(firstPage - mmPaginationConstants.maxNumberOfButtons, firstPage);
            }

            /**
             * Shifting pages right means the opposite of shifting left, i.e
             * [1, 2, 3, 4, 5] to [6, 7, 8, 9, 10].
             *
             * Then there is a specific case where you might not have 10 page numbers available, i.e:
             * [1, 2, 3, 4, 5] to [6, 7]
             *
             * @param lastPage the last page in the current range, i.e: 5
             */
            function shiftPagesRight(lastPage) {
                var endIndex = getBeginIndexForPage(lastPage) + vm.paginationModel.maxPerPage;
                var remainingButtons = (vm.paginationModel.max - endIndex) / vm.paginationModel.maxPerPage;
                remainingButtons = _.min([remainingButtons, mmPaginationConstants.maxNumberOfButtons]);

                vm.pages = _.range(lastPage + 1, lastPage + remainingButtons + 1);
            }

            /**
             * Returns true if pages should move to the left.
             * @param firstPageInRange
             * @returns {boolean}
             */
            function shouldShiftPagesLeft(firstPageInRange) {
                return vm.paginationModel.beginIndex < getBeginIndexForPage(firstPageInRange);
            }

            /**
             * Returns true if pages should move to the right.
             * @param lastPageInRange
             * @returns {boolean}
             */
            function shouldShiftPagesRight(lastPageInRange) {
                return vm.paginationModel.beginIndex > getBeginIndexForPage(lastPageInRange);
            }

            function hasNext() {
                return vm.paginationModel.beginIndex < vm.paginationModel.max - vm.paginationModel.maxPerPage;
            }

            function hasPrevious() {
                return vm.paginationModel.beginIndex >= vm.paginationModel.maxPerPage;
            }

            /**
             * Current endIndex of the displayed result
             * @returns {*}
             */
            this.endIndex = function () {
                var endIndex = this.paginationModel.beginIndex + this.paginationModel.maxPerPage;
                return _.min([endIndex, this.paginationModel.max]);
            };

            /**
             * Shows the previous page, and shifts the page numbers if needed
             */
            this.showPrevious = function () {
                if (hasPrevious()) {
                    this.paginationModel.beginIndex -= this.paginationModel.maxPerPage;

                    var firstPageInRange = this.pages[0];
                    if (shouldShiftPagesLeft(firstPageInRange)) {
                        shiftPagesLeft(firstPageInRange);
                    }
                }
            };

            /**
             * Shows the next page, and shifts the page numbers if needed
             */
            this.showNext = function () {
                if (hasNext()) {
                    this.paginationModel.beginIndex += this.paginationModel.maxPerPage;

                    var lastPageInRange = this.pages[this.pages.length - 1];
                    if (shouldShiftPagesRight(lastPageInRange)) {
                        shiftPagesRight(lastPageInRange);
                    }
                }
            };

            /**
             * Go to a specific page index
             * @param page
             */
            this.goTo = function (page) {
                this.paginationModel.beginIndex = getBeginIndexForPage(page);
            };

            /**
             * Determines which page index that is active
             * @param page
             * @returns {boolean}
             */
            this.isActive = function (page) {
                return this.paginationModel.beginIndex === getBeginIndexForPage(page);
            };

            /**
             * To display X number of records per page
             * @param recordsPerPage one of the alternatives i.e: 15,25,50,100,Alla
             */
            this.setMaxPerPage = function (recordsPerPage) {
                var maxPerPage = parseInt(recordsPerPage);
                //if "Alla"
                if (isNaN(maxPerPage)) {
                    maxPerPage = this.paginationModel.max;
                }

                this.paginationModel.maxPerPage = maxPerPage;
                this.initPages(this.paginationModel.max);
            };

            /**
             * To re-render the pagination buttons.
             * Based on totalNumberOfRecords and maxPerPage (number of records per page)
             * @param totalNumberOfRecords
             */
            this.initPages = function (totalNumberOfRecords) {
                var numberOfButtons = totalNumberOfRecords / this.paginationModel.maxPerPage;
                numberOfButtons = _.min([mmPaginationConstants.maxNumberOfButtons, numberOfButtons]);
                this.pages = _.range(1, numberOfButtons + 1);
                this.paginationModel.beginIndex = 0;
            };
        },
        link: function link(scope) {
            scope.paginationController.paginationModel = _.defaults(scope.paginationController.paginationModel || {}, {
                maxPerPage: mmPaginationConstants.maxPerPage,
                beginIndex: 0,
                max: mmPaginationConstants.max
            });

            /**
             * When max is changing, for instance when a filter changes the data length,
             * this callback handles the number of page numbers to display and resets the beginIndex to 0
             */
            scope.$watch(function () {
                return scope.paginationController.paginationModel.max;
            }, function (maxLength) {
                scope.paginationController.initPages(maxLength);
            });

            scope.paginationController.maxPerPageAlternatives = _.sortBy(_.union(mmPaginationConstants.maxPerPageAlternatives, [scope.paginationController.paginationModel.maxPerPage]));
        }
    };
}
angular.module('resources.ui.table.pagination', []).directive('mmPaginationControl', PaginationControl).constant('mmPaginationConstants', {
    maxNumberOfButtons: 5,
    maxPerPage: 15,
    max: 200,
    maxPerPageAlternatives: [15, 25, 50, 100, "Alla"]
});