'use strict';

LoginService.$inject = ['$log', '$http', 'LoginConfigurer', 'CookieService', '$rootScope'];
EnvironmentService.$inject = ['$q', '$resource', '$log', 'LoginConfigurer'];
CookieService.$inject = ['$log', 'EnvironmentService'];
angular.module('maxlogin', ['ui.router', 'ngResource', 'maxlogin.templates']).run(function () {
    mmMessages.load();
}).config(['$stateProvider', '$provide', function ($stateProvider, $provide) {
    $provide.value("mmMessages", mmMessages);
    $stateProvider.state('login', {
        url: '/login',
        templateUrl: '/src/partial/login.html',
        data: {
            pageTitle: 'Inloggning'
        },
        params: {
            returnStateName: null
        },
        controller: 'LoginCtrl'
    });
    /* Add New States Above */
}]).factory('LoginModule', ['$q', '$transitions', 'EnvironmentService', 'LoginService', 'StatusService', '$rootScope', '$state', function ($q, $transitions, EnvironmentService, LoginService, StatusService, $rootScope, $state) {
    var prevState, prevStateParams;

    return {

        previousState: function previousState() {
            return prevState !== undefined ? prevState.name : undefined;
        },

        previousStateParams: function previousStateParams() {
            return prevStateParams;
        },

        /**
         * Initializes the login module
         * @param pollServer an optional parameter to initiate a poll on every state change in angular ui router.
         * @returns {d.promise|*|promise}
         */
        init: function init(pollServer) {
            if (pollServer) {
                $transitions.onStart({}, function (trans) {
                    if (trans.to().name !== 'login') {
                        prevState = trans.to();
                        prevStateParams = trans.params();
                        return StatusService.pollOnce().then(angular.noop, function () {
                            LoginService.logout().then(function () {
                                return trans.router.stateService.target('login');
                            });
                        });
                    }
                });
            }

            var deferred = $q.defer();

            EnvironmentService.get().then(LoginService.updateLoginState).then(function (loginState) {
                deferred.resolve(loginState);
            }).then(function () {
                if (pollServer) {
                    return StatusService.startPolling();
                }
            });

            return deferred.promise;
        }
    };
}]);

//empty placeholder
angular.module('maxlogin.templates', []);
angular.module('maxlogin.templates', []).run(['$templateCache', function ($templateCache) {
    $templateCache.put('/src/directive/mmNavbarLogin.html', '<li class="dropdown" id="user-dropdown">\n    <a href="" class="dropdown-toggle" data-toggle="dropdown" role="button" accesskey="!"><i class="fa fa-user fa-lg fa-fw"></i></a>\n    <ul class="dropdown-menu dropdown-menu-right" role="menu">\n        <li>\n            <span class="dropdown-text"><i class="fa fa-user fa-fw"></i> {{ navbarLogin.loginState.currentUser }} </span>\n        </li>\n        <li class="divider"></li>\n        <li ng-show="navbarLogin.loginState.isLoggedIn">\n            <a href="" accesskey="h" ng-click="navbarLogin.logout()"><i class="fa fa-sign-out fa-fw"></i> Logga <u>u</u>t</a>\n        </li>\n        <li ng-show="!navbarLogin.loginState.isLoggedIn">\n            <a href="" accesskey="h" ui-sref="login"><i class="fa fa-sign-out fa-fw"></i> Logga <u>i</u>n</a>\n        </li>\n    </ul>\n</li>\n');
    $templateCache.put('/src/partial/login.html', '<section>\n    <div class="row">\n        <div class="col-md-4 col-md-offset-4">\n            <h2>Inloggning</h2>\n\n            <form>\n                <div class="form-group">\n                    <label for="username">Anv\xE4ndarnamn</label>\n                    <input type="text" ng-model="username" class="form-control" id="username"\n                           placeholder="Anv\xE4ndarnamn">\n                </div>\n                <div class="form-group">\n                    <label for="password">L\xF6senord</label>\n                    <input type="password" ng-model="password" class="form-control" id="password"\n                           placeholder="L\xF6senord">\n                </div>\n                <button ng-click="login()" type="submit" class="btn btn-maxm-primary pull-right"><i\n                    class="fa fa-sign-in"></i> Logga in\n                </button>\n            </form>\n        </div>\n    </div>\n</section>\n');
}]);
angular.module('maxlogin').directive('mmNavbarLogin', function () {
    return {
        restrict: 'AE',
        replace: true,
        templateUrl: '/src/directive/mmNavbarLogin.html',
        controllerAs: 'navbarLogin',
        scope: {},
        controller: ['LoginService', '$state', function controller(LoginService, $state) {
            this.loginState = LoginService.getLoginState();

            this.logout = function () {
                LoginService.logout().then(function () {
                    $state.go('login');
                });
            };
        }]
    };
});
angular.module('maxlogin').controller('LoginCtrl', ['$scope', '$state', 'LoginService', 'mmMessages', 'LoginConfigurer', 'LoginModule', function ($scope, $state, LoginService, mmMessages, LoginConfigurer, LoginModule) {

    $scope.login = function () {

        LoginService.login($scope.username, $scope.password).then(function () {
            mmMessages.appendMessage("Inloggad som " + $scope.username, mmMessages.types.SUCCESS, true);

            if ($state.params.returnStateName) {
                $state.go($state.params.returnStateName);
            } else {
                $state.go(LoginModule.previousState() || LoginConfigurer.getDefaultState(), LoginModule.previousStateParams() || {});
            }
        }, function () {
            mmMessages.appendMessage("Inloggningen misslyckades", mmMessages.types.DANGER, true);

            $scope.username = '';
            $scope.password = '';
        });
    };
}]);

function CookieService($log, EnvironmentService) {

    var cookieName = 'maxAuthToken_';

    function toUTF8(s) {
        /* jshint ignore:start */
        return decodeURIComponent(escape(s));
        /* jshint ignore:end */
    }

    // Can't get ng-cookie to work, so let's parse it like this for now.
    function getCookie(cname) {
        var name = cname + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    function _parseCookie(cookie) {
        // The cookie consists of who pieces separated by a colon.
        // The first piece is the payload, which is a Base64 encoded JSON string.
        if (!cookie) {
            return null;
        }
        $log.debug('Parsing the authentication cookie');

        var pieces = cookie.split(':');
        if (pieces.length != 2) {
            return null;
        }
        var parsedCookie = JSON.parse(atob(pieces[0]));
        if (parsedCookie.props && parsedCookie.props.name) {
            parsedCookie.props.name = toUTF8(parsedCookie.props.name);
        }
        return parsedCookie;
    }

    return {
        parseCookie: function parseCookie() {
            return EnvironmentService.get().then(function (environment) {
                var env;
                if (environment.env === 'local') {
                    // Use DEV for local, for easier development of various micro services
                    env = 'dev';
                } else {
                    env = environment.env;
                }

                return _parseCookie(getCookie(cookieName + env));
            });
        }
    };
}

angular.module('maxlogin').factory('CookieService', CookieService);

function EnvironmentService($q, $resource, $log, LoginConfigurer) {

    var EnvironmentResource = $resource(LoginConfigurer.getPath() + '/environment');
    var environment;

    function loadEnvironment() {
        var deferred = $q.defer();

        if (environment) {
            deferred.resolve(environment);
        } else {
            EnvironmentResource.get().$promise.then(function (response) {
                return response.data;
            }).then(function (env) {
                $log.debug('Loaded environment: ' + JSON.stringify(env));
                environment = env;
                deferred.resolve(environment);
            });
        }

        return deferred.promise;
    }

    return {
        get: loadEnvironment
    };
}
angular.module('maxlogin').factory('EnvironmentService', EnvironmentService);

angular.module('maxlogin').provider('LoginConfigurer', function () {
    var path, defaultState;

    this.setPath = function (backendResourcePath) {
        path = backendResourcePath;
    };

    this.setDefaultState = function (state) {
        defaultState = state;
    };

    this.$get = [function () {
        return {
            getPath: function getPath() {
                return path;
            },

            getDefaultState: function getDefaultState() {
                return defaultState || 'home';
            }
        };
    }];
});

function LoginService($log, $http, LoginConfigurer, CookieService, $rootScope) {

    var state = {
        isLoggedIn: false,
        currentUser: 'Ej inloggad'
    };

    function refreshLoginState() {
        return CookieService.parseCookie().then(function (cookie) {
            if (cookie) {
                state.currentUser = cookie.props.name;
                state.isLoggedIn = true;
            } else {
                state.isLoggedIn = false;
                state.currentUser = 'Ej inloggad';
            }
            $rootScope.$broadcast('loginStateUpdated', state);
            return state;
        });
    }

    return {

        login: function login(username, password) {
            return $http({
                method: 'POST',
                url: LoginConfigurer.getPath() + '/login',
                data: $.param({
                    username: username,
                    password: password
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            }).then(function () {
                return refreshLoginState();
            });
        },

        logout: function logout() {
            return $http({
                method: 'POST',
                url: LoginConfigurer.getPath() + '/logout',
                headers: {
                    'Accept': 'application/json'
                }
            }).then(function () {
                return refreshLoginState();
            }, function (data, status) {
                $log.debug("logout failed with status", status);
            });
        },
        updateLoginState: refreshLoginState,

        getLoginState: function getLoginState() {
            return state;
        },

        isLoggedIn: function isLoggedIn() {
            return state.isLoggedIn;
        }
    };
}

angular.module('maxlogin').factory('LoginService', LoginService);

angular.module('maxlogin').factory('StatusService', ['$resource', 'LoginConfigurer', '$interval', '$q', 'LoginService', '$state', function ($resource, LoginConfigurer, $interval, $q, LoginService, $state) {

    var lastSuccessResponse;
    var intervalPromise;
    var Status = $resource(LoginConfigurer.getPath() + '/status');

    var THRESHOLD = 600000;

    function poll() {
        var deferred = $q.defer();

        Status.get().$promise.then(function (data) {
            lastSuccessResponse = new Date();
            deferred.resolve(data);
        }, function (errorResponse) {
            deferred.reject(errorResponse);
        });
        return deferred.promise;
    }

    return {

        startPolling: function startPolling() {
            //initial poll
            var promise = poll();

            //poll every 10 minutes
            intervalPromise = $interval(function () {
                poll().then(angular.noop, function () {
                    LoginService.logout().then(function () {
                        $state.go('login');
                    });
                });
            }, THRESHOLD);

            return promise;
        },

        pollOnce: function pollOnce() {
            if (angular.isDefined(intervalPromise)) {
                //reset the timer and poll again
                $interval.cancel(intervalPromise);
                return this.startPolling();
            } else {
                return poll();
            }
        }

    };
}]);